import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import highfive from "../images/highfive.svg"
import logo from "../images/logo@4x.png"
import largeArrow from "../images/largeArrow.svg"

import HowItWorks from "../components/howItWorks"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Mega Mentor @mega_mentor"
      description="Mega Mentor helps you find and connect with experienced mentors across the design community"
    />
    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered is-vcentered">
            <div className="column is-half">
              <div className="container logo-container">
                <img src={highfive} alt="" />
              </div>
            </div>
            <div className="column is-half ">
              <div className="container">
                <img src={logo} alt="Mega Mentor" />
              </div>
              <div className="container has-margin-top-3">
                <h2 className="title is-family-secondary has-text-black">
                  Helping designers to lead
                </h2>
                <div className="has-margin-top-2">
                  <div className="subtitle has-text-black is-marginless">
                    <p className="has-margin">
                      Finding senior and lead-level design mentors is hard, so
                      we've brought them together for you.
                    </p>
                    <p>Use their experience to develop your own.</p>
                    <p>
                      You can meet multiple people, as many times as you need,
                      for free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section has-background-white-bis">
      <div className="columns is-vcentered">
        <div className="column is-one-third ">
          <div className="arrow">
            <img src={largeArrow} alt="" />
          </div>
          <div className="section">
            <div className="container">
              <h2 className="title is-family-secondary has-text-black">
                All our mentors
              </h2>

              <div className="content has-text-black">
                <p>
                  Find the person who best fits the conversation you'd like to
                  have.
                </p>
                <p>
                  You can search and filter them by their expertise, location,
                  sector or what you'd like to talk about.{" "}
                </p>
                <p>
                  Once you've found them, use their Calendly link to book time
                  with them.
                </p>
                <p>Only one rule: no sales or hiring pitches.</p>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="container">
              <h2 className="title is-family-secondary has-text-black">
                Who can mentor
              </h2>
              <div className="content has-text-black">
                <p>
                  Anyone with experience in leading teams or projects, who feels
                  ready to support others and is intrigued by the benefits of
                  being a mentor.
                </p>
                <p>
                  You need a bit of spare time - pre-work, lunchtimes or
                  evenings work well.
                </p>
                <p>
                  You can set your availability, and find what works for you.
                </p>
              </div>
              <div className="container has-margin-top-3">
                <Link
                  to="/signup"
                  className="button is-black is-rounded is-uppercase has-text-weight-semibold"
                >
                  Become a Mentor
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-two-thirds">
          <iframe
            title="Mentor Search"
            className="airtable-embed"
            src="https://airtable.com/embed/shrnxzrUDMH3ykyD7?backgroundColor=purple&viewControls=on"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="600"
            style={{}}
          ></iframe>
        </div>
      </div>
    </section>

    <section className="columns is-marginless">
      <HowItWorks />
    </section>
    <section className="section has-background-white-bis">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds has-text-black">
            <h2 className="title is-family-secondary has-text-black">
              We wanted to remove the awkwardness of asking for a new mentor's
              help, and make it easier for a mentor to offer it.
            </h2>
            <div className="columns">
              <div className="column">
                <p>
                  Traditional models of mentoring mainly focus on supporting
                  juniors, are very time consuming and require a long term
                  commitment.
                </p>
              </div>
              <div className="column">
                <p>
                  We struggled in the past to find a mentor and we setup Mega
                  Mentor to make it easier to find and be one.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
